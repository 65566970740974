import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Seo from '../../components/Seo';
import Layout from '../../components/layout/Layout';
import SplitSection from '../../components/SplitSection';
import CalloutSimple from '../../components/CalloutSimple';
import ArrowDown from '../../components/ArrowDown';

import customerdemo from '../../assets/drawings/customerdemo.png';
import salesrep from '../../assets/drawings/salesrep.png';
import democonvert from '../../assets/drawings/democonvert.png';


export default () => {
  const title = 'Share your project with stakeholders';
  const description =
    'With Strio, share what you coded, whether it be a commit, a feature or a whole product! Easily create ephemeral environments that you can use to present demos of your work.';
  const url = 'https://strio.app/usecases/demos';

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <section id="hero-small" className="relative pa-0 bg-lownight overflow-hidden">
        <div className="background" />

        <div className="absolute w-full flex flex-row justify-center bottom-0">
          <AnchorLink
            href="#features"
            className="h-16 z-1 mb-4 lg:mb-6 rounded-full hover:bg-gray-300 transition duration-300 ease-in-out"
          >
          </AnchorLink>
        </div>
        <div className="flex items-center min-h-full relative">
          <div className="container pt-12 lg:pt-24 pb-16 mb-12 px-4">
            <div className="lg:px-8">
              <div className="text-center text-white md:text-center">
                <h1 className="text-3xl bg:text-2xl sm:text-4xl lg:text-5xl xl:text-5xl font-bold leading-none">
                Demo environments <br/> for your prospects and customers
                </h1>
                <div className="flex justify-center">
                  <p className="font-light leading-snug text-base lg:text-xl xl:text-2xl mt-6 tracking-tight w-2/3">
                  Generate demo environments in just a click. Maximize conversion 
                  thanks to personalized demos that your prospects can even try on their own.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white">
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={salesrep}
              alt="Sales rep drawing"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Empower Sales Reps
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Sales reps can easily deploy their own demo environment directly from Slack. 
              No need to code anything or to wait for the demo environment to be available!              
              </div>
            </div>
          }
        />
        <SplitSection
          primarySlot={
            <img
              src={democonvert}
              alt="Conversion demo drawing"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Create personalized demos for maximal conversion
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Personalize demos for each prospect. Simply provision the demo environment 
              with specific data and elements. 
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <img
              src={customerdemo}
              alt="Customer demo image"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Let your prospects test the product on their own
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Give access to the environments to your prospects and let 
              them test your product! Strio environments are fully 
              isolated, so prospects won't break anything.
              </div>
            </div>
          }
        />
      </section>
      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
